<template>
  <sub-page
      :title="$t('Settings')"
      v-model="$store.state.settings.pageData"
      :header-action="OPEN_NEW_ENTITY"
      :header-action-text="$t('gamification.achievements.add')"
      :header-action-condition="items.length > 0"
      :no-content-condition="items.length === 0 "
      :no-content-button-action="OPEN_NEW_ENTITY"
      :no-content-button-text="$t('gamification.achievements.add')"
      no-content-icon="mdi-star"
      :no-content-text="$t('gamification.achievements.no_content')"
      :loading="loading"

  >
    <template #default>

      <ws-data-table
          :items="items"
          :headers="headers"
          :row-action="OPEN_EDIT_ENTITY"
          class="mt-5"
      >

        <template #item.name="{item}">
          <div class="d-flex align-center">
            <v-avatar size="60" class="mr-4">
              <v-img height="60" width="60" :src="item.img" />
            </v-avatar>
            <h5>{{ item.name }}</h5>
          </div>
        </template>

        <template #item.type="{item}">
          <h5 class="text-no-wrap">{{ getTypeName(item.type) }}</h5>
        </template>

        <template #item.action="{item,hover}">

          <div style="width: 50px">
            <v-btn v-if="hover" @click.stop="OPEN_DELETE_ENTITY(item)" :color="wsACCENT" icon >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </div>

        </template>

      </ws-data-table>

    </template>

    <template #dialog>

      <!-- Add Edit Entity -->
      <ws-dialog
          @save="API('ADD_EDIT_ACHIEVEMENT')"
          v-if="displayDialog"
          v-model="displayDialog"
          :title="newEntity ? $t('gamification.achievements.add') : $t('gamification.achievements.edit')"
          :show-delete="!newEntity"
      >

        <ws-text-field
            v-model="entityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
        />

        <ws-select
            v-model="entityData.type"
            :items="achievementsTypesSelect"
            :label="$t('Condition')"
            :placeholder="$t('IssueManual')"
        />
        <ws-text-field
            v-if="quantityTypes.includes(entityData.type)"
            v-model="entityData.quantity"
            :label="$t('Quantity')"
            :placeholder="$t('EnterQuantity')"
        />
        <image-param-picker
            v-model="entityData.img"
        />



      </ws-dialog>

      <!-- Delete Entity -->
      <ws-dialog
          v-model="displayDeleteDialog"
          :title="$t('gamification.achievements.delete')"
          display-confirm-delete
          close-delete
          @delete="API('DELETE_ACHIEVEMENT' )"
      />

    </template>
  </sub-page>
</template>

<script>

import entitiesFunctions from "@/mixins/entitiesFunctions";
import imageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker";

export default {
  name: "achievementsList",
  mixins : [entitiesFunctions],
  init : 'gamification/GET_ACHIEVEMENTS',
  components : {
    imageParamPicker
  },
  headers :  [
    { name : 'Name' , value : 'name' },
    { name : 'Condition' , value : 'type' , width : 10 },
    { name : 'Value' , value : 'quantity' , width : 10 },
    { value : 'action' , width : 10 },
  ],
  data() {
    return {
      quantityTypes : ['course_amount']
    }
  },
  computed : {
    achievementsTypesSelect() {
      return [
        { text : this.$t('gamification.achievements.condition.course_amount') , value : 'course_amount' },
        { text : this.$t('gamification.achievements.condition.manual') , value : null },
      ]
    }
  },
  methods : {
    getTypeName(type) {
      let typeEntity = this.achievementsTypesSelect.find(el => el.value === type)
      return typeEntity ? typeEntity.text : null
    }
  }
}
</script>

<style scoped>

</style>